
html, body{
    font-family: 'Josefin Sans', sans-serif;
    
}
.task_container
{
    width: 50%;
    min-width: 400px;
    text-align: center;
    float:left;
margin-top: 4%;
}
#div_to_print
{
    margin: 0 auto;

}
.space_inline
{
    display: inline-block;
}
.task_container p
{
    font-family: monospace;
    margin: 5px 0px;
}
.rectangle{
    width: 10px!important;
    height: 10px!important;
    display: inline-block;
    background: black;
}
@media (min-width: 992px) {
    .logout{
        margin-left: auto !important;
    } }
.currentone{
    color:#fff !important;

}
.printable{
    position: absolute;
    width: 100%;
    height: 100%;
    background: blue;
}


.slidecontainer {
    width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 25px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #4CAF50; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #4CAF50; /* Green background */
    cursor: pointer; /* Cursor on hover */
}